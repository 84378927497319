<template>
  <v-container>
    <v-row align="center">
      <v-col
        sm="8"
        md="8"
        lg="6"
        xl="4"
        class="mx-auto"
      >
        <img
          :src="require('../assets/logo.png')"
          height="30px"
          alt=""
        >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-form
            ref="form"
            class="text-left"
            @submit.prevent
          >
            <v-row>
              <v-col>
                <h2 class="mb-2">
                  Attendi mentre configuriamo il tuo account
                </h2>
                <p>Verrai reindirizzato alla pagina di login, utilizza le credenziali create in precedenza.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-progress-linear
                v-model="value"
                height="25"
                :color="isValid ? 'primary' : 'red'"
              >
                <strong>{{ Math.ceil(value) }}%</strong>
              </v-progress-linear>
            </v-row>
            <v-row v-if="!isValid">
              <v-col class="text-center">
                <p class="red--text">
                  {{ errorMessage }}
                </p>
                <v-btn
                  color="primary"
                  :to="{name:'login'}"
                >
                  Vai alla pagina di login
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="manualRedirect"
      class="my-4"
    >
      <v-col class="text-center">
        <span>Il redirect automatico non funziona? </span> <v-btn
          class="no-uppercase"
          text
          color="primary"
          :to="{name:'login'}"
        >
          Clicca qui
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    value: 0,
    interval: 0,
    manualRedirect: false,
    isValid: true,
    errorMessage: '',
  }),
  computed: {
    ...mapGetters(['formRules']),
  },
  mounted() {
    this.startBuffer()
    this.confirmAccount()
  },
  methods: {
    async confirmAccount() {
      const resp = await this.$api.confirmAccount(this.$route.params.vs)
      if (resp.data.status !== 'success') {
        this.errorMessage = resp.data.message
      }
    },
    startBuffer() {
      clearInterval(this.interval)

      this.interval = setInterval(() => {
        const increment = Math.random() * (15 - 5) + 5
        if ((this.value + increment) > 100) {
          this.value = 100
          clearInterval(this.interval)
          this.redirectToLogin()
        } else if ((this.value + increment) > 30 && this.errorMessage !== '') {
          this.isValid = false
          clearInterval(this.interval)
          this.value = 100
        } else {
          this.value += increment
        }
      }, 1000)
    },
    redirectToLogin() {
      this.timeout = setTimeout(() => {
        this.$router.push({ name: 'login' })
      }, 1000)
    },
    showManualRedirect() {
      this.timeout = setTimeout(() => {
        this.manualRedirect = true
      }, 2000)
    },
  },
}
</script>
<style scoped>
    .no-uppercase {
        text-transform: unset !important;
    }
</style>
