import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"mx-auto",attrs:{"sm":"8","md":"8","lg":"6","xl":"4"}},[_c('img',{attrs:{"src":require('../assets/logo.png'),"height":"30px","alt":""}}),_c(VCard,{staticClass:"mx-auto pa-4 pa-sm-6 pa-md-16 my-4",staticStyle:{"max-width":"600px"},attrs:{"elevation":"6"}},[_c(VForm,{ref:"form",staticClass:"text-left",on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mb-2"},[_vm._v(" Attendi mentre configuriamo il tuo account ")]),_c('p',[_vm._v("Verrai reindirizzato alla pagina di login, utilizza le credenziali create in precedenza.")])])],1),_c(VRow,[_c(VProgressLinear,{attrs:{"height":"25","color":_vm.isValid ? 'primary' : 'red'},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.value))+"%")])])],1),(!_vm.isValid)?_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c('p',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c(VBtn,{attrs:{"color":"primary","to":{name:'login'}}},[_vm._v(" Vai alla pagina di login ")])],1)],1):_vm._e()],1)],1)],1)],1),(_vm.manualRedirect)?_c(VRow,{staticClass:"my-4"},[_c(VCol,{staticClass:"text-center"},[_c('span',[_vm._v("Il redirect automatico non funziona? ")]),_vm._v(" "),_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":"","color":"primary","to":{name:'login'}}},[_vm._v(" Clicca qui ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }